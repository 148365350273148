.flex{
    display: flex;
}

.align-center{
    align-items: center;
}   

.justify-center{
    justify-content: center;
}

.size{
    width: 50%;
    height: 100%;
}

.height{
    height: 96px
}

.text-size{
    font-size: 96px;
}

.text-col{
    color: #fff;
}

.vision_size{
    width: 98vw;
    height: 100vh;
}
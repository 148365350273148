.image_style{
    width : 98vw;
    display: flex;
    align-items: center;
    justify-content: center;
}

.img_sty{
    width: 60%;
    align-items: center;

}

.comp_size2{
    width: 100vm;
    height: 100vh;
}

@media (max-width: 455px) {
    .comp_size2{
        width: 100vm;
        height: 50vh;
    }
}
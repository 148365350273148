.container {
    display: flex;
    position: relative;
    width: 50%; 
    height: auto;
    margin-left: 25%;
    margin-right: 25%;
    margin-top: 5%;
  }

  .image {
    display: block;
    width: 100%;
    height: auto;
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity 0.3s ease;
    pointer-events: none; 
  }

  .container.hover .overlay {
    opacity: 1;
  }